import './ErrorsPage.scss';

function ErrorPage(){
    return(
        <div className='container'>
            <div className='error__text__error__page'>
                <h1> Ci scusiamo purtroppo la pagina ricercata non è disponibile</h1>
            </div>
        </div>
    )
}

export default ErrorPage;