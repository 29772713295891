import './ContactsPage.scss';

export default function ContactsPage(){

    
    return(
        <section>
            <div className='contacts__page'>
                <div className='container'>
                    <h1 className='contacts__title'>Contattaci per altre informazioni</h1> 
                    <div className='contacts__page__content'>
                        <div className='contacts__page__content__form'>
                            <div className='form__mail'>
                                <div className='possition__leotech__info'>
                                    <h2>E-mail:</h2>
                                    <p>info@leotech.cloud</p>
                                    <h2>Telefono o WhatsApp:</h2>
                                    <p>+39 388 351 7713</p>
                                    <h2>Indirizzo:</h2>
                                    <p>Tarquinia, via Aldo Moro, Centro commerciale TOP16, 2 piano.</p>
                                </div>
                                <div className='social'>
                                    <h2>Seguici sui social:</h2>
                                    <div className='social-main'>
                                        <a href="https://www.instagram.com/leotech.it/"  target="_blank" rel="noreferrer">Instagram</a>
                                        <a href="https://www.facebook.com/LeoTech.CAI" target="_blank" rel="noreferrer">FaceBook</a>
                                        <a href="https://www.linkedin.com/company/leo-tech-informatica/?viewAsMember=true" target="_blank" rel="noreferrer">Linkedin</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='possition__leotech'>
                            <div className='possition__leotech__map'>
                                <iframe title='leotech_google_maps' src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Leotech%20Tarquinia+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                </iframe>
                            </div>
                        </div> 
                    </div>
                </div>  
            </div>       
        </section>
    );

}