import web_assistenza from "../img/webIMG/Assistenza.png";
import web_consulenza from "../img/webIMG/consulenza.png";
import web_installazioneDispositivi from "../img/webIMG/installazioneDispositivi.png";
import web_ProgettazioneDati from "../img/webIMG/ProgettazioneDati.png";
import web_RecuperoDati from "../img/webIMG/RecuperoDati.png";
import web_sicurezza from "../img/webIMG/sicurezza.png";
import web_tempo from "../img/webIMG/tempo.png";


const WebData = [
    {
        "title": "Modalità assistenza",
        "img": web_assistenza,
        "text": "Assistenza presso i vostri uffici, assistenza telefonica e, ove possibile, assistenza remota per la soluzione di tutte le problematiche relative alla gestione sistemistica del server e dei PC connessi in rete.",
        "id": 1
    },
    {
        "title": "Progettazione Dati",
        "img": web_ProgettazioneDati,
        "text": "Installazione e manutenzione continua del software di backup per l'esecuzione giornaliera del backup del server e di tutti i PC client.",
        "id": 2
    },
    {
        "title": "Sicurezza",
        "img": web_sicurezza,
        "text": "Installazione e manutenzione del software antivirus con programmazione giornaliera, settimanale e mensile delle scansioni periodiche di controllo.",
        "id": 3
    },
    {
        "title": "Recupero Dati",
        "img": web_RecuperoDati,
        "text": "Recupero dati in caso di infezione da virus tipo cryptolocker e simili.",
        "id": 4
    },
    {
        "title": "Installazione dispositivi",
        "img": web_installazioneDispositivi,
        "text": "Assistenza tecnica per l'installazione di nuove periferiche, stampanti, scanner, etc.",
        "id": 5
    },
    {
        "title": "Consulenza",
        "img": web_consulenza,
        "text": "Consulenza gratuita per l'acquisto di nuovi prodotti, servizi, etc e scontistica riservata per l'acquisto di nuovo hardware.",
        "id": 6
    },
    {
        "title": "Tempistiche intervento",
        "img": web_tempo,
        "text": "Tempo di intervento massimo entro 24/48 ore dalla richiesta del cliente a seconda dell'urgenza.",
        "id": 7
    },
]

export default WebData;