import React, {Component} from 'react';
import Card from './Cards/Card.js';
import './HomePage.scss';

import telefonia from '../../img/Cards/telefonia.png';
import pc from '../../img/Cards/pc.png';
import web from '../../img/Cards/web.png';


class HomePage extends Component{

    state = {
        cards: [
            {id: 0, name: "Telefonia", image: telefonia, link: '../telefonia', text: "Effetuiamo riparazioni hardware e software su smartphone e tablet di tutti i brand"},

            {id: 1, name: "Computer", image: pc, link: '../informatica', text: "Effetuiamo vendita di PC e Notebook nuovi e ricondizionati. Riparazioni hardware e software su tutti i btand"},

            {id: 2, name: "Aziende", image: web, link: '../Web', text: "Offriamo servizi personalizzati e innovativi, risultato di approccio integrato e multidisciplinare"}
        ],
    }

    render() {

        return(
            <>
            <main>
                <div className='page__content'>
                    <div className='cards'>
                        <div className='container'>
                            <h2>I nostri servizi</h2>
                            <div className='cards__content row'>
                                {this.state.cards.map(card =>(
                                    <Card 
                                    key = {card.id}
                                    card = {card}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            </>
        )
    }
}

export default HomePage