import React, {Component} from 'react';
import './web.scss';
import WebData from '../../data/data_web.js'

class Web extends Component{
    render(){
        return(
            <main>
                <div className='web'>
                    <div className='container'>
                        <div className='web__content'>
                        <h1>Aziende</h1>
                        <h6>La nostra <span>Mission</span> è costruire con i
                        nostri clienti relazioni che durino
                        nel tempo, basate su rapporti seri, leali
                        e trasparenti.
                        Fornire soluzioni moderne ed innovative
                        che risolvano i problemi e contribuiscano
                        allo sviluppo e alla crescita del loro
                        business</h6>
                            <div className='web__card'>
                                {WebData.map(item =>
                                <section className='web__card__content'>
                                    <img src={item.img} alt={item.title}/>
                                    <h2>{item.title}</h2>
                                    <p>{item.text}</p>
                                </section>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default Web;

